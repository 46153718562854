.login-container {
	padding: 20px;
	position: relative;
	background-color: #f0f0f0;
	min-height: 450px;
}

.login-container.remember-me {
	min-height: 250px;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}

.login-container.forgot-pw {
	min-height: 250px;
	display: flex;
	flex-direction: column;
}

.login-container > h2 {
	font-size: 1.9rem;
	font-weight: 100;
	letter-spacing: -0.04em;
	line-height: 1em;
	-webkit-text-size-adjust: 100%;
	margin-bottom: 20px;
}

.login-container > p {
	font-size: 0.9rem;
	line-height: 1.7em;
	margin-bottom: 20px;
}

.login-form .input-row,
.login-container.forgot-pw .input-row {
	display: flex;
	flex-direction: column;
	margin-bottom: 20px;
}

.input-row > label,
.input-item > label {
	font-weight: 600;
	font-size: 18px;
	margin-bottom: 5px;
	letter-spacing: -0.04em;
	display: flex;
	justify-content: space-between;
}

.label-error {
	font-size: 16px;
	color: red;
	display: none;
}

.label-error.show {
	display: inline-block;
}

.input-row > input,
.input-item > input {
	width: 100%;
	height: 40px;
	line-height: 40px;
	box-shadow: inset 0 5px 10px -10px #000;
	border: none;
	padding: 6px 12px;
	font-size: 14px;
	color: #555555;
	transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
}

.input-row > input.error,
.input-item > input.error,
.provinces-dropdown.error {
	background-color: #ffdddd;
}

.submission-row {
	display: flex;
	justify-content: space-between;
}

.submission-item {
	display: flex;
	align-items: center;
}

.submission-item > label {
	display: block;
	position: relative;
	cursor: pointer;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	font-size: 18px;
}

.submission-item > label > input[type='checkbox'] {
	position: absolute;
	opacity: 0;
	cursor: pointer;
	height: 0;
	width: 0;
	/* width: 20px;
	height: 20px;
	border: none;
	box-shadow: inset 0 5px 10px -10px #000; */
}

.checkmark {
	position: absolute;
	top: 0;
	left: 0;
	height: 20px;
	width: 20px;
	background-color: #ffffff;
	box-shadow: inset 0 5px 10px -10px #000;
	transition: background-color 250ms ease-in-out;
}

.submission-item > label:hover .checkmark {
	background-color: #dddddd;
}

.checkmark:after {
	content: '';
	position: absolute;
	display: none;
}

.submission-item > label > input.checked ~ .checkmark:after {
	display: block;
}

.submission-item > label .checkmark:after {
	left: 6px;
	top: 3px;
	width: 5px;
	height: 10px;
	border: solid #64ac1e;
	border-width: 0 3px 3px 0;
	-webkit-transform: rotate(45deg);
	-ms-transform: rotate(45deg);
	transform: rotate(45deg);
}

.checkbox-label {
	display: inline-block;
	margin-left: 30px;
}

.button {
	padding: 5px 10px;
	min-width: 75px;
	height: 40px;
	background-color: #64ac1e;
	color: #ffffff;
	border: none;
	font-size: 16px;
}

.action-row {
	display: flex;
	flex-direction: column;
	margin-top: 20px;
}

.action-item {
	display: flex;
	margin-bottom: 5px;
}

.action-icon {
	display: flex;
	justify-content: center;
	align-items: baseline;
	width: 25px;
	height: 25px;
	border-radius: 50%;
	background-color: #64ac1e;
	color: white;
	margin-right: 10px;
	cursor: pointer;
}

.green-icon {
	width: 25px;
	height: 25px;
	margin-right: 10px;
	cursor: pointer;
}

.action-text {
	color: #64ac1e;
	cursor: pointer;
	padding-top: 2px;
}

.error-message {
	color: red;
}
