.login-container.not-found {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}

.not-found h2 {
	margin-bottom: 20px;
}

.not-found .button {
	width: auto;
}
