.SpinnerWrapper {
	text-align: center;
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: rgba(240, 240, 240, 1);
	z-index: 10;
}

.Spinner {
	display: inline-block;
	position: relative;
	width: 64px;
	height: 64px;
}
.Spinner div {
	animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
	transform-origin: 32px 32px;
}
.Spinner div:after {
	content: ' ';
	display: block;
	position: absolute;
	width: 6px;
	height: 6px;
	border-radius: 50%;
	background: #65ac1e;
	margin: -3px 0 0 -3px;
}
.Spinner div:nth-child(1) {
	animation-delay: -0.036s;
}
.Spinner div:nth-child(1):after {
	top: 50px;
	left: 50px;
}
.Spinner div:nth-child(2) {
	animation-delay: -0.072s;
}
.Spinner div:nth-child(2):after {
	top: 54px;
	left: 45px;
}
.Spinner div:nth-child(3) {
	animation-delay: -0.108s;
}
.Spinner div:nth-child(3):after {
	top: 57px;
	left: 39px;
}
.Spinner div:nth-child(4) {
	animation-delay: -0.144s;
}
.Spinner div:nth-child(4):after {
	top: 58px;
	left: 32px;
}
.Spinner div:nth-child(5) {
	animation-delay: -0.18s;
}
.Spinner div:nth-child(5):after {
	top: 57px;
	left: 25px;
}
.Spinner div:nth-child(6) {
	animation-delay: -0.216s;
}
.Spinner div:nth-child(6):after {
	top: 54px;
	left: 19px;
}
.Spinner div:nth-child(7) {
	animation-delay: -0.252s;
}
.Spinner div:nth-child(7):after {
	top: 50px;
	left: 14px;
}
.Spinner div:nth-child(8) {
	animation-delay: -0.288s;
}
.Spinner div:nth-child(8):after {
	top: 45px;
	left: 10px;
}
@keyframes lds-roller {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}
