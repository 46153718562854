.information-container {
	display: flex;
	justify-content: space-between;
}

.information-item {
	flex: 0 1 50%;
	margin-right: 40px;
}

.information-item h4 {
	margin-bottom: 10px;
	font-weight: 200;
	font-size: 18px;
}

.form-container > p {
	margin: 30px 0;
}

.register-form .input-row {
	display: flex;
	width: 100%;
	justify-content: space-between;
	margin-bottom: 20px;
}

.register-form .input-row > .input-item.half {
	flex: 0 1 48%;
}

.register-form .input-row > .input-item.full {
	flex: 0 1 100%;
}

.input-item > label {
	font-size: 15px;
}

.provinces-dropdown {
	font-size: 14px;
	border: none;
}

.register-error {
	color: red;
	display: none;
	margin-bottom: 20px;
}

.register-error.show {
	display: block;
}

.success-content > p:nth-of-type(1) {
	margin: 20px 0;
}

.superScript {
	vertical-align: super;
	font-size: 10px;
}

@media only screen and (max-width: 768px) {
	.information-container {
		flex-wrap: wrap;
	}

	.information-item {
		flex: 0 1 100%;
		margin-right: 0;
		margin-bottom: 20px;
	}

	.form-container > p {
		margin: 10px 0 30px 0;
	}

	.register-form .input-row {
		flex-wrap: wrap;
		margin-bottom: 0;
	}

	.register-form .input-row > .input-item.full,
	.register-form .input-row > .input-item.half {
		flex: 0 1 100%;
		margin-bottom: 20px;
	}
}
