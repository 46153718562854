* {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
	text-size-adjust: 100%;
}

.app {
	background-color: #808080;
	height: 100vh;
	color: #333333;
	font-family: 'Helvetica Neue LT W06', 'Helvetica Neue LT W06_45 Light',
		'Helvetica Neue LT W06_55 Roman', sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.bold {
	font-weight: bold;
}
