.login-container.forgot-pw h2,
.duplicate-content {
	margin-bottom: 20px;
}

.login-container.forgot-pw p {
	margin-bottom: 10px;
}

.duplicates-dropdown,
.provinces-dropdown {
	height: 40px;
	width: 100%;
	font-size: 20px;
	font-weight: 100;
}

.success-content {
	margin: 10px 0 30px 0;
}

/* .button.success {
	width: 125px;
} */

.error-message {
	color: red;
	display: none;
	font-weight: bold;
}

.error-message.show {
	display: block;
}
